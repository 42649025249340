import React from 'react';
import styled from 'styled-components';

import normalize from 'helpers/normalizeCss';

import ThemeProviderComponent from 'components/common/ThemeProvider';

const FullScreenContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<ThemeProviderComponent>
			<Container>{children}</Container>
		</ThemeProviderComponent>
	);
};

const Container = styled.div`
	${normalize}

	width: 100%;
	min-height: 100vh;
	position: relative;
`;

export default FullScreenContainer;
