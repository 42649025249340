import React from 'react';
import Seo from '../components/seo';

import { AvailableItem, ClinicsAvailability } from 'apiFolder/availability';
import { IAvailabilityPageInformation, ImageData, LatLng, SuburbData } from 'apiFolder/directus-main';

import FullScreenContainer from 'components/booking/FullScreenContainer';
import MainContainer from 'components/booking/Main';

export interface IClinic {
	full_address: string;
	id: number;
	location: { lat: number; lng: number };
	name: string;
	slug: string;
	logoPicture?: string;
	distance?: number;
	available?: ClinicsAvailability;
	suburbName?: string;
	images_data: ImageData;
}

export interface IClinics {
	data: IClinic[];
	size: number;
}

export interface ISuburb {
	id: number;
	name: string;
	label?: string;
	postcode: string;
	state: {
		text: string;
	};
	location: { lat: number; lng: number } | null;
	slug: string;
}

// --- directus interfaces ------
export interface ClinicCard {
	id: number;
	name: string;
	full_address: string;
	slug: string;
	location: LatLng;
	distance: number;
	images_data: ImageData | null;
	available?: AvailableItem[];
	time?: string;
	popular?: boolean;
}
// --- directus interfaces end------
export interface Context {
	pageContext: {
		item: SuburbData;
		pageInformation: IAvailabilityPageInformation;
		suburbClinics: ClinicCard[];
		clinicsBookNow: ClinicCard[];
		suburbSlugs: string[];
		dataSuburbSlugs: string[];
	};
}

const BookingPage: React.FunctionComponent<Context> = ({ pageContext }) => {
	return (
		<>
			<Seo
				title={pageContext.item?.meta_title || 'Find & Book a Physio Appointment Now - BookPhysio.com'}
				description={
					pageContext.item?.meta_description ||
					'Enter your suburb to find physiotherapy clinics in your area. Easily find appointments that fit your schedule and book instantly.'
				}
				markupSchema={pageContext.item?.markup_schema}
			/>
			<FullScreenContainer>
				<MainContainer pageContext={pageContext} label={''} />
			</FullScreenContainer>
		</>
	);
};

export default BookingPage;
